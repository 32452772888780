import "./Footer.scss";

function FooterCol1() {
  return (
    <>
      <div className="footerCol1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <h5>Global Sitemap</h5>

              <div className="custom">
                <ul>
                  <li>
                    <a href="#">
                      <span>About Us</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Our Services</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Products</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Clients</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>News &amp; Info</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Contact Us</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="company-detail">
                <ul>
                  <li>PT PETALING OMPAY BERKAH TEKNOLOGI SOFTWAREINDO</li>
                  <li>
                    Jl, Sijantung no 32 Batam
                  </li>
                  <li>
                    No telp :
                    <a
                      href="tel:+081292366337"
                      className="no-underline primary-color"
                    >
                      +08 129 236 6337
                    </a>
                  </li>
                  <li>
                    Email :{" "}
                    <a
                      href="ompayteknologisoftwareindo@gmail.com"
                      className="no-underline primary-color"
                    >
                      ompayteknologisoftwareindo@gmail.com
                    </a>
                  </li>
                </ul>
                <span className="copy-right">
                  Copyright © 2023 PT PETALING OMPAY BERKAH TEKNOLOGI SOFTWAREINDO
                </span>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterCol1;
