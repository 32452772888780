import "./Home.scss";
import home1 from "../assets/images/home1.png";
import home2 from "../assets/images/home4.png";
import home3 from "../assets/images/home5.png";
import f1 from "../assets/images/f1.png";
import f2 from "../assets/images/f2.png";
import f3 from "../assets/images/f3.png";
import f4 from "../assets/images/f4.png";

// import product0101 from "../assets/images/04/01-01.jpg";

function HomeContent1() {
  return (
    <div className="home-content-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <img src={home1} alt="Home 1" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <img src={home2} alt="Home 2" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <img src={home3} alt="Home 3" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="box clearfix">
              <img src={f1} alt="f1" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="box clearfix">
              <img src={f2} alt="f2" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="box clearfix">
              <img src={f3} alt="f3" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="box clearfix">
              <img src={f4} alt="f4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeContent1;
