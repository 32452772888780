import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import ContactUs from "./Components/ContactUs/ContactUs";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Header from "./Header/Header";

interface TitleProps {
  title: string;
}

function Title({ title }: TitleProps) {
  useEffect(() => {
    document.title = `${title.toUpperCase()}`;
  }, [title]);

  return null;
}

function App() {
  const [currentPage, setCurrentPage] = useState("home");

  const handlePageChange = (newPage: React.SetStateAction<string>) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="App">
      <Title title={`PT PETALING OMPAY BERKAH TEKNOLOGI SOFTWAREINDO - ${currentPage}`} />
      <Header currentPage={currentPage} onPageChange={handlePageChange} />
      {currentPage === "home" && <Home />}
      {/* {currentPage === "contact" && <ContactUs />} */}
      <Footer />
    </div>
  );
}

export default App;
