import "./Home.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCartPlus, faCreditCard, faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";

import dg_item1 from "../assets/images/dg_item1.png";
import dg_item2 from "../assets/images/dg_item2.png";
import dg_item3 from "../assets/images/dg_item3.png";
import dg_item4 from "../assets/images/dg_item4.png";
import dg_item5 from "../assets/images/dg_item5.png";
import dg_item6 from "../assets/images/dg_item6.png";
import dg_item7 from "../assets/images/dg_item7.png";
import dg_item8 from "../assets/images/dg_item8.png";
import dg_item9 from "../assets/images/dg_item9.png";
import dg_item10 from "../assets/images/dg_item10.png";
import dg_item11 from "../assets/images/dg_item11.png";
import dg_item12 from "../assets/images/dg_item12.png";
import dg_item13 from "../assets/images/dg_item13.png";
import dg_item14 from "../assets/images/dg_item14.png";
import dg_item15 from "../assets/images/dg_item15.png";
import dg_item16 from "../assets/images/dg_item16.png";
import dg_item17 from "../assets/images/dg_item17.png";
import dg_item18 from "../assets/images/dg_item18.png";
import dg_item19 from "../assets/images/dg_item19.png";
import dg_item20 from "../assets/images/dg_item20.png";
import dg_item21 from "../assets/images/dg_item21.png";
import dg_item22 from "../assets/images/dg_item22.png";
import dg_item23 from "../assets/images/dg_item23.png";
import dg_item24 from "../assets/images/dg_item24.png";
import dg_item25 from "../assets/images/dg_item25.png";
import dg_item26 from "../assets/images/dg_item26.png";
import dg_item27 from "../assets/images/dg_item27.png";

import he_item1 from "../assets/images/he1_item1.png";
import he_item2 from "../assets/images/he1_item2.png";
import he_item3 from "../assets/images/he1_item3.png";
import he_item4 from "../assets/images/he1_item4.png";
import he_item5 from "../assets/images/he1_item5.png";
import he_item6 from "../assets/images/he1_item6.png";
import he_item7 from "../assets/images/he1_item7.png";
import he_item8 from "../assets/images/he1_item8.png";
import he_item9 from "../assets/images/he1_item9.png";
import he_item10 from "../assets/images/he1_item10.png";
import he_item11 from "../assets/images/he1_item11.png";
import he_item12 from "../assets/images/he1_item12.png";
import he_item13 from "../assets/images/he1_item13.png";

import ka_item1 from "../assets/images/ka1_item1.png";
import ka_item2 from "../assets/images/ka1_item2.png";
import ka_item3 from "../assets/images/ka1_item3.png";
import ka_item4 from "../assets/images/ka1_item4.png";
import ka_item5 from "../assets/images/ka1_item5.png";
import ka_item6 from "../assets/images/ka1_item6.png";
import ka_item7 from "../assets/images/ka1_item7.png";
import ka_item8 from "../assets/images/ka1_item8.png";
import ka_item9 from "../assets/images/ka1_item9.png";
import ka_item10 from "../assets/images/ka1_item10.png";
import ka_item11 from "../assets/images/ka1_item11.png";
import ka_item12 from "../assets/images/ka1_item12.png";
import ka_item13 from "../assets/images/ka1_item13.png";

import ha_item1 from "../assets/images/ha1_item1.png";
import ha_item2 from "../assets/images/ha1_item2.png";
import ha_item3 from "../assets/images/ha1_item3.png";
import ha_item4 from "../assets/images/ha1_item4.png";
import ha_item5 from "../assets/images/ha_item5.png";
import ha_item6 from "../assets/images/ha1_item6.png";
import ha_item7 from "../assets/images/ha1_item7.png";
import ha_item8 from "../assets/images/ha1_item8.png";
import ha_item9 from "../assets/images/ha1_item9.png";
import ha_item10 from "../assets/images/ha1_item10.png";
import ha_item11 from "../assets/images/ha1_item11.png";
import ha_item12 from "../assets/images/ha1_item12.png";
import ha_item13 from "../assets/images/ha1_item13.png";
import ha_item14 from "../assets/images/ha1_item14.png";
import ha_item15 from "../assets/images/ha1_item15.png";
import ha_item16 from "../assets/images/ha1_item16.png";
import ha_item17 from "../assets/images/ha1_item17.png";

import { useState } from "react";
import { post } from "../Helper/http";
import { sortBy } from "lodash";

type Product = {
  id: number;
  imgSrc: string;
  title: string;
  price: string;
};

function HomeContent2() {
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState<Product[]>([]);
  const [activeView, setActiveView] = useState("tabs"); // 'tabs' or 'cart'
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
  const [subTotal, setSubTotal] = useState("");

  // Function to handle adding items to the cart
  const addToCart = (product: Product) => {
    setCartCount(cartCount + 1);
    setCartItems([...cartItems, product]);
  };

  const handleCartReset = () => {
    if (cartCount > 0) {
      setCartCount(0); // Reset cart count
      setCartItems([]); // Clear cart items
      setSubTotal("0");
  
      toast.success("Cart has been reset!", {
        position: "top-center",
        autoClose: 3000,
      });
    } else {
      toast.info("Cart is already empty!", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatWithCommas = (number: any) =>
    new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  
  const handleCartTab = () => {
    if (cartCount > 0) {
      setActiveView("cart");
      setSubTotal(cartItems
        .reduce((total, item) => {
          const numericPrice = parseFloat(
            item.price.replace(/IDR/g, "").replace(/,/g, "").trim()
          );
          return total + (isNaN(numericPrice) ? 0 : numericPrice);

        }, 0)
        .toFixed(2));
    }
    else {
      toast.info("Your cart is empty! Add some items to view the cart.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
      // alert("Your cart is empty! Add some items to view the cart.");
  };

  const back = () => {
    setActiveView("tabs");
  }

  const proceedToPayment = async () => {
    setLoading(true); 
    setPaymentStatus(null);
    const trxNumber: string = Math.floor(100000000 + Math.random() * 900000000).toString();
    const amount: string = cartItems
                    .reduce((total, item) => {
                      const numericPrice = parseFloat(
                        item.price.replace(/IDR/g, "").replace(/,/g, "").trim()
                      );
                      return total + (isNaN(numericPrice) ? 0 : numericPrice);
                    }, 0)
                    .toFixed(2);

    // const requestUrl = `https://qris.online/restapi/qris/show_qris.php?do=create-invoice&apikey=139139231008077&mID=FM2407230350&cliTrxNumber=${trxNumber}&cliTrxAmount=${amount}`;
    // const { status, data } = await get(requestUrl); // Replace with your API URL
    const url = `https://api.dreamkpay.com/request-deposit`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: any = {
      merchantId: "lfe22wyl314xnc1fgeswwefu",
      countryId: "ID",
      ip: "127.0.0.0", 
      amount: amount,
      referenceId: trxNumber,
      notificationUrl: "https://petalingompayberkah.com/",
      redirectUrl: "https://petalingompayberkah.com/",
      payType: "1004"
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestHashStr: any = {
      merchantId: "lfe22wyl314xnc1fgeswwefu",
      countryId: "ID",
      ip: "127.0.0.0", 
      apiKey: "te45gboalph3aj28w36b2g5r", 
      amount: amount,
      referenceId: trxNumber,
      notificationUrl: "https://petalingompayberkah.com/",
    }

    const hashStr = getHashStr(requestHashStr);
    const hash = hashStr + '&w4c2p9yej0i67irntnxmmyq8';
    request.sign = md5(hash).toLowerCase();

    console.log(request);
    const resp = await post(url, request);
    console.log(resp);

    if (resp && resp.data && resp.data.success) {
      window.open(resp.data.data, "_blank"); 
      // setPaymentStatus("Payment initiated successfully!");
    } else {
      toast.info("Payment gateway is in maintenance. Please try again later,", {
        position: "top-center",
        autoClose: 3000,
      });
      // setPaymentStatus(`Failed to initiate payment: ${data}`);
    }
    
    setLoading(false); // Hide loader or re-enable button
  };

  const dg_products = [
    {
      id: 1,
      imgSrc: dg_item1,
      title: "Insta360 X4 Camera",
      price: "7,000,000 IDR",
    },
    {
      id: 2,
      imgSrc: dg_item2, 
      title: "Apple Watch Series 10 GPS and Cellular Sport Band",
      price: "4,800,000 IDR ",
    },
    {
      id: 3,
      imgSrc: dg_item3,
      title: "AirPods 4",
      price: "2,400,000 IDR",
    },
    {
      id: 4,
      imgSrc: dg_item4,
      title: "Insta360 G0 3S Camera",
      price: "5,000,000 IDR",
    },
    {
      id: 5,
      imgSrc: dg_item5,
      title: "Samsung Galaxy Buds3 / Buds3 Pro AI Translator Wireless Bluetooth Earbuds",
      price: "3,900,000 IDR",
    },
    {
      id: 6,
      imgSrc: dg_item6,
      title: "Samsung Galaxy Watch7 Bluetooth 40mm / 44mm, Smartwatch with AI wellness Tracker",
      price: "4,400,000 IDR",
    },
    {
      id: 7,
      imgSrc: dg_item7,
      title: "UGREEN 10000mAh Powerbank PD20W",
      price: "220,000 IDR",
    },
    {
      id: 8,
      imgSrc: dg_item8,
      title: "Powerbank 200000mah PD66W",
      price: "180,000 IDR",
    },
    {
      id: 9,
      imgSrc: dg_item9,
      title: "V380 PRO PD66W Power Banks",
      price: "220,000 IDR",
    },
    {
      id: 10,
      imgSrc: dg_item10,
      title: "YYDS Powerbank 100000mah PD66W",
      price: "400,000 IDR",
    },
    {
      id: 11,
      imgSrc: dg_item11,
      title: "DOSEN mini Powerbank Fast Charging 12000mah 20W",
      price: "600,000 IDR",
    },
    {
      id: 12,
      imgSrc: dg_item12,
      title: "K500 Gaming Wired Keyboard Mixed Colorful Backlight Luminous Mechanical",
      price: "95,000 IDR",
    },
    {
      id: 13,
      imgSrc: dg_item13,
      title: "10-inch Wireless Bluetooth Keyboard",
      price: "65,000 IDR",
    },
    {
      id: 14,
      imgSrc: dg_item14,
      title: "Punk Luminous Keyboard Wired Keyboard",
      price: "140,000 IDR",
    },
    {
      id: 15,
      imgSrc: dg_item15,
      title: "K82 Gaming Gaming Keyboard Glows Wired USB",
      price: "180,000 IDR",
    },
    {
      id: 16,
      imgSrc: dg_item16,
      title: "M96 Wireless Bluetooth Dual Mode Mechanical Feel Keyboard",
      price: "150,000 IDR",
    },
    {
      id: 17,
      imgSrc: dg_item17,
      title: "DC403 44MP 1080P HD Digital Camera",
      price: "500,000 IDR",
    },
    {
      id: 18,
      imgSrc: dg_item18,
      title: "4K Digital Camera Autofocus Vlog Camera",
      price: "1,000,000 IDR",
    },
    {
      id: 19,
      imgSrc: dg_item19,
      title: "Portable 1080P HD Camera",
      price: "330,000 IDR",
    },
    {
      id: 20,
      imgSrc: dg_item20,
      title: "Digital Camera for, FHD 1080P Point & Shoot Compact Camera",
      price: "250,000 IDR",
    },
    {
      id: 21,
      imgSrc: dg_item21,
      title: "1.1-inch Sports Smart Watch Touch Screen Fitness Tracker",
      price: "300,000 IDR",
    },
    {
      id: 22,
      imgSrc: dg_item22,
      title: "New Smart Watch For Men - Wireless Talk Sports Fitness AI Voice",
      price: "400,000 IDR",
    },
    {
      id: 23,
      imgSrc: dg_item23,
      title: "AWSUG Women'S Smartwatch Gift, 1.27-inch HD Fitness Tracker with Wireless Calling & Messaging",
      price: "500,000 IDR",
    },
    {
      id: 24,
      imgSrc: dg_item24,
      title: "1080P HD Dual-Lens Car DVR with 64GB High-Speed Memory Card, Fast Car Phone Mount, 12-Hour Recording, Auto Loop, Gravity Sensor",
      price: "310,000 IDR",
    },
    {
      id: 25,
      imgSrc: dg_item25,
      title: "ZKCAMSPY 4 Channel Dash Cam, 1080P Ultra HD Car Camera with Night Vision, Loop Recording, 24H Parking Monitor",
      price: "355,000 IDR",
    },
    {
      id: 26,
      imgSrc: dg_item26,
      title: "Wireless Earphones with LED Digital Display",
      price: "220,000 IDR",
    },
    {
      id: 27,
      imgSrc: dg_item27,
      title: "Lenovo ThinkPlus GM2 PRO Wireless Earbuds",
      price: "450,000 IDR",
    },
  ];

  const he_products = [
    {
      id: 1,
      imgSrc: he_item1,
      title: "FENZO SADA Mini USB 2.1 Wired Bluetooth Speaker Combination Bass Music Subwoofer",
      price: "140,000 IDR",
    },
    {
      id: 2,
      imgSrc: he_item2, 
      title: "KTS-1057 BLUETOOTH SPEAKER PORTABLE SPEAKER WIRELESS SPEAKER",
      price: "100,000 IDR",
    },
    {
      id: 3,
      imgSrc: he_item3,
      title: "4in1 Wireless Speaker LED Atmosphere Light RGB Bluetooth Speaker",
      price: "300,000 IDR",
    },
    {
      id: 4,
      imgSrc: he_item4,
      title: "HY300 Portable Projector 4K HD Android 11 Wireless 5G Wifi+Bluetooth",
      price: "640,000 IDR",
    },
    {
      id: 5,
      imgSrc: he_item5,
      title: "Portable Home Theater YG300 Mini Projector Smart TV Laser Beamer",
      price: "320,000 IDR",
    },
    {
      id: 6,
      imgSrc: he_item6,
      title: "T20 Phone Wifi Screen Smart Projector",
      price: "350,000 IDR",
    },
    {
      id: 7,
      imgSrc: he_item7,
      title: "HY320 Projector 4K Android 11 Native 1080P",
      price: "780,000 IDR",
    },
    {
      id: 8,
      imgSrc: he_item8,
      title: "Vintage Record-Style Wireless Speaker with RGB LED Night Light & Dynamic Ambient Lights",
      price: "480,000 IDR",
    },
    {
      id: 9,
      imgSrc: he_item9,
      title: "ABS Multi-Device Wireless Charging Station with Alarm Clock",
      price: "250,000 IDR",
    },
    {
      id: 10,
      imgSrc: he_item10,
      title: "LED Desk Lamp, With Wireless Charger, USB Charging Port, Desk Lamp With Clock, Alarm Clock, Date, Temperature, Foldable Desk Lamp",
      price: "350,000 IDR",
    },
    {
      id: 11,
      imgSrc: he_item11,
      title: "ClocTeck Nixie Tube Clock with Wi-Fi Time Calibration",
      price: "680,000 IDR",
    },
    {
      id: 12,
      imgSrc: he_item12,
      title: "Black Mini Stereo Speaker with 360-Degree Surround Sound, 7 LED Lighting Effects",
      price: "320,000 IDR",
    },
    {
      id: 13,
      imgSrc: he_item13,
      title: "ZEALOT 10W Wireless Speakers With Wireless Microphone",
      price: "460,000 IDR",
    },
  ];

  const ka_products = [
    {
      id: 1,
      imgSrc: ka_item1,
      title: "Panasonic Rechargeable Sonic Electric Toothbrush",
      price: "250,000 IDR",
    },
    {
      id: 2,
      imgSrc: ka_item2, 
      title: "Aurora D. Smart Sonic Pro Electric Toothbrush",
      price: "500,000 IDR",
    },
    {
      id: 3,
      imgSrc: ka_item3,
      title: "Lenovo Smart Electric Toothbrush IPX7",
      price: "280,000 IDR",
    },
    {
      id: 4,
      imgSrc: ka_item4,
      title: "Bathroom LED Round Smart Makeup Mirror Diameter 70cm",
      price: "355,000 IDR",
    },
    {
      id: 5,
      imgSrc: ka_item5,
      title: "Oval Smart Bathroom Mirror (50x80cm)",
      price: "800,000 IDR",
    },
    {
      id: 6,
      imgSrc: ka_item6,
      title: "LED Bathroom Mirror with Light Tri-color & Time temperature Display",
      price: "580,000 IDR",
    },
    {
      id: 7,
      imgSrc: ka_item7,
      title: "Luxury Brass Shower System Set",
      price: "700,000 IDR",
    },
    {
      id: 8,
      imgSrc: ka_item8,
      title: "HOTU Smart Sensor Tissue Box - USB Rechargeable",
      price: "300,000 IDR",
    },
    {
      id: 9,
      imgSrc: ka_item9,
      title: "Wall-Mounted Smart Toothbrush Sterilizer with UV Dryer And Toothbrush Holder",
      price: "360,000 IDR",
    },
    {
      id: 10,
      imgSrc: ka_item10,
      title: "Smart Automatic Foaming Soap Dispenser",
      price: "280,000 IDR",
    },
    {
      id: 11,
      imgSrc: ka_item11,
      title: "Smart Aroma Diffuser with 5-Speed Adjustable One Click Switch",
      price: "100,000 IDR",
    },
    {
      id: 12,
      imgSrc: ka_item12,
      title: "Kemei Multi-functional Reciprocating Electric Shaver With LCD Display",
      price: "250,000 IDR",
    },
    {
      id: 13,
      imgSrc: ka_item13,
      title: "New Mini Electric Portable Dual Blade Floating Men's Electric Shaver",
      price: "185,000 IDR",
    },
  ];

  const ha_products = [
    {
      id: 1,
      imgSrc: ha_item1,
      title: "JISULIFE 3 in 1 Portable Fan 9000mAh Rechargeable With Air Duster Function",
      price: "890,000 IDR",
    },
    {
      id: 2,
      imgSrc: ha_item2, 
      title: "JISULIFE Handheld Fan Portable Bladeless Mini Fan",
      price: "450,000 IDR",
    },
    {
      id: 3,
      imgSrc: ha_item3,
      title: "2024 Mini Turbo ganas kipas Mini 3nd generasi",
      price: "300,000 IDR",
    },
    {
      id: 4,
      imgSrc: ha_item4,
      title: "Air Purifier Smoke Odor Negative Ion Generator",
      price: "300,000 IDR",
    },
    {
      id: 5,
      imgSrc: ha_item5,
      title: "Midea 7-Inch Stand Circulation Fan with 3-Speed Levels MFG180M0APB",
      price: "850,000 IDR",
    },
    {
      id: 6,
      imgSrc: ha_item6,
      title: "Levoit Core Mini Portable Desktop Air Purifier",
      price: "750,000 IDR",
    },
    {
      id: 7,
      imgSrc: ha_item7,
      title: "Hulmers - Indoor Air Purifier",
      price: "550,000 IDR",
    },
    {
      id: 8,
      imgSrc: ha_item8,
      title: "Yilizomana Air Circulation Fan",
      price: "220,000 IDR",
    },
    {
      id: 9,
      imgSrc: ha_item9,
      title: "Smart Digital Weight Scale",
      price: "360,000 IDR",
    },
    {
      id: 10,
      imgSrc: ha_item10,
      title: "Automatic Smart Trash Can with Infrared Motion Sensor",
      price: "260,000 IDR",
    },
    {
      id: 11,
      imgSrc: ha_item11,
      title: "Multifunctional Security Camera Wireless Outdoor",
      price: "280,000 IDR",
    },
    {
      id: 12,
      imgSrc: ha_item12,
      title: "Lenovo 4K Dual-lens Dual-picture Wireless Indoor Smart Camera",
      price: "570,000 IDR",
    },
    {
      id: 13,
      imgSrc: ha_item13,
      title: "WJG 1080P HD WiFi Security Camera with Auto Tracking",
      price: "260,000 IDR",
    },
    {
      id: 14,
      imgSrc: ha_item14,
      title: "Smart Robot WiFi Camera with Auto-Tracking",
      price: "300,000 IDR",
    },
    {
      id: 15,
      imgSrc: ha_item15,
      title: "Advanced Air Purifier 2024, Triple Deep Filtration, Intelligent Touch Screen, Negative Ion Emission",
      price: "700,000 IDR",
    },
    {
      id: 16,
      imgSrc: ha_item16,
      title: "Space Robot Design HD WiFi Home Monitor Camera",
      price: "1,400,000 IDR",
    },
    {
      id: 17,
      imgSrc: ha_item17,
      title: "Smart Owl Indoor Security Camera with Ultra-Wide Angle Vision, Two-Way Audio, Humanoid Recognition Alarm",
      price: "890,000 IDR",
    },
  ];
  
  return (
    <div className="home-content-2">
      <div className="container">
        {/* <h5>
          <span>PT PETALING OMPAY BERKAH | Konsultan IT | Jakarta | Indonesia</span>
        </h5> */}
        <div className="container-header">
          <h5>
            <span>PT PETALING OMPAY BERKAH | Konsultan IT | Jakarta | Indonesia</span>
          </h5>
          <div 
            className="cart-reset-icon" 
            onClick={() => handleCartReset()} 
            style={{ cursor: "pointer", marginLeft: "10px" }}
          >
            <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
            <span className="reset-text" style={{ marginLeft: "5px", color: "red" }}>Reset</span>
          </div>
          <div 
            className="cart-icon"
            onClick={() => handleCartTab()}
            style={{ cursor: "pointer", marginLeft: "auto" }}
            >
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className="cart-count">{cartCount}</span>
            <ToastContainer />
          </div>
          
        </div>
        
        { activeView === "tabs" && 
          (
            <>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="digital_gadgets"
                  role="tabpanel"
                  aria-labelledby="digital_gadgets"
                  tabIndex={0}
                >
                  <div className="product-slider">
                  {dg_products.map((product) => (
                    <div className="item-block" key={product.id}>
                      <div className="image-wrap">
                        <img
                          className="image"
                          width="215"
                          height="215"
                          src={product.imgSrc}
                          alt={product.title}
                        />
                      </div>
                      <div className="product-details">
                        <div className="product-details-title">
                          <span className="product-name cutwords-two-line">{product.title}</span>
                        </div>
                        <div className="product-details-footer">
                          <span className="product-price">{product.price}</span>
                          <button
                            onClick={() => addToCart(product)} 
                            className="add-to-cart-btn"
                          >
                            <FontAwesomeIcon icon={faCartPlus} style={{ marginRight: "3px" }} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="home_entertainment"
                  role="tabpanel"
                  aria-labelledby="home_entertainment"
                  tabIndex={1}
                >
                  <div className="product-slider">
                  {he_products.map((product) => (
                    <div className="item-block" key={product.id}>
                      <div className="image-wrap">
                        <img
                          className="image"
                          width="215"
                          height="215"
                          src={product.imgSrc}
                          alt={product.title}
                        />
                      </div>
                      <div className="product-details">
                        <div className="product-details-title">
                          <span className="product-name cutwords-two-line">{product.title}</span>
                        </div>
                        <div className="product-details-footer">
                          <span className="product-price">{product.price}</span>
                          <button
                            onClick={() => addToCart(product)} 
                            className="add-to-cart-btn"
                          >
                            <FontAwesomeIcon icon={faCartPlus} style={{ marginRight: "3px" }} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="bathroom_appliances"
                  role="tabpanel"
                  aria-labelledby="bathroom_appliances"
                  tabIndex={2}
                >
                  <div className="product-slider">
                  {ka_products.map((product) => (
                    <div className="item-block" key={product.id}>
                      <div className="image-wrap">
                        <img
                          className="image"
                          width="215"
                          height="215"
                          src={product.imgSrc}
                          alt={product.title}
                        />
                      </div>
                      <div className="product-details">
                        <div className="product-details-title">
                          <span className="product-name cutwords-two-line">{product.title}</span>
                        </div>
                        <div className="product-details-footer">
                          <span className="product-price">{product.price}</span>
                          <button
                            onClick={() => addToCart(product)} 
                            className="add-to-cart-btn"
                          >
                            <FontAwesomeIcon icon={faCartPlus} style={{ marginRight: "3px" }} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="home_appliances"
                  role="tabpanel"
                  aria-labelledby="home_appliances"
                  tabIndex={3}
                >
                  <div className="product-slider">
                  {ha_products.map((product) => (
                    <div className="item-block" key={product.id}>
                      <div className="image-wrap">
                        <img
                          className="image"
                          width="215"
                          height="215"
                          src={product.imgSrc}
                          alt={product.title}
                        />
                      </div>
                      <div className="product-details">
                        <div className="product-details-title">
                          <span className="product-name cutwords-two-line">{product.title}</span>
                        </div>
                        <div className="product-details-footer">
                          <span className="product-price">{product.price}</span>
                          <button
                            onClick={() => addToCart(product)} 
                            className="add-to-cart-btn"
                          >
                            <FontAwesomeIcon icon={faCartPlus} style={{ marginRight: "3px" }} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              </div>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="btn-digital-gadgets"
                    data-bs-toggle="tab"
                    data-bs-target="#digital_gadgets"
                    type="button"
                    role="tab"
                    aria-controls="digital_gadgets"
                    aria-selected="true"
                  >
                    Digital Gadgets
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="btn-home-entertainment"
                    data-bs-toggle="tab"
                    data-bs-target="#home_entertainment"
                    type="button"
                    role="tab"
                    aria-controls="home_entertainment"
                    aria-selected="false"
                  >
                    Home Entertainment
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="btn-bathroom-appliances"
                    data-bs-toggle="tab"
                    data-bs-target="#bathroom_appliances"
                    type="button"
                    role="tab"
                    aria-controls="bathroom_appliances"
                    aria-selected="false"
                  >
                    Bathroom Appliances
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="btn-home-appliances"
                    data-bs-toggle="tab"
                    data-bs-target="#home_appliances"
                    type="button"
                    role="tab"
                    aria-controls="home_appliances"
                    aria-selected="false"
                  >
                    Home Appliances
                  </button>
                </li>
              </ul>
            </>
          )
        }
        

        { activeView === "cart" && 
          (
            <>
              <div className="tab-cart-items">
                <div className="cart">
                    <h3>Cart Items</h3>
                    {cartItems.map((item: Product, index) => (
                      <div key={index} className="cart-item">
                        <img src={item.imgSrc} alt={item.title} width="50" />
                        <span
                          tabIndex={0} style={{ marginRight: "10px" }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              console.log(`Selected: ${item.title}`);
                            }
                          }}
                        >{item.title}</span>
                        <span tabIndex={0}>{item.price}</span>
                      </div>
                    ))}
                    
                </div>
              </div>
              <div className="all-button">
                <div className="sub-total">
                  <span style={{fontWeight: "600" }}>
                    Sub Total: IDR {formatWithCommas(subTotal)}
                  </span>
                </div>
                <button 
                  onClick={back}
                  className="btn-back">
                  <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "5px" }} />
                  Back
                </button>
                <button
                  onClick={proceedToPayment}
                  className="btn-proceed-payment"
                  disabled={loading} // Disable button while loading
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{ marginRight: "3px" }}
                  />
                  {loading ? "Processing..." : "Proceed to Payment"}
                </button>

                {/* Display payment status */}
                {paymentStatus && <div className="payment-status">{paymentStatus}</div>}
              </div>
            </>
          )
        }
          
      </div>
    </div>
  );
}

export default HomeContent2;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHashStr = (request: any) => {
  let strArray = Object.keys(request).map((key) => {
      if (key !== 'sign'){
          return { "key": key, "value": request[key] }
      }
  });

  strArray = strArray.filter(function (element) {
      return element !== undefined;
  });

  const sortedStrArray = sortBy(strArray, 'key');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryStr = sortedStrArray.map((element: any) => {
      return "" + element.key + "=" + element.value; // line break for wrapping only
  }).join("&");

  return queryStr.toString().replace(",", "");
}


export const md5 = (contents: string) => CryptoJS.MD5(contents).toString();