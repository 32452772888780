import "./Footer.scss";
import FooterCol from "./FooterCol";

function Footer() {
  return (
    <>
      <div className="footer">
          <FooterCol></FooterCol>
      </div>
    </>
  );
}

export default Footer;
