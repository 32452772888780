import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import banner1 from "../assets/images/banner.png";
// import banner2 from "../assets/images/banner2.png";
// import banner3 from "../assets/images/banner3.png";
import banner4 from "../assets/images/banner4.png";
import banner5 from "../assets/images/banner5.png";

const BannerSlider = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
        {/* <img src={banner1} alt="Banner 1" />
        <img src={banner2} alt="Banner 2" />
        <img src={banner3} alt="Banner 3" /> */}
        <img src={banner4} alt="Banner 4" />
        <img src={banner5} alt="Banner 5" />
    </Slider>
  );
};

export default BannerSlider;
