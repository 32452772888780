import axios, { AxiosError } from "axios";

export const get = async (url: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let returnData: any;
    let returnStatus = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: any = {
        method: 'get',
        url: url,
        timeout: 6000
    };

    await axios(request).then((resp) => {
        returnStatus = resp.status;
        returnData = resp.data;
    }).catch((error) => {
        const err = error as AxiosError
        if (err.response) {
            returnStatus = err.response.status;
            returnData = JSON.stringify(err.response.data);
        }
    });

    return { status: returnStatus, data: returnData }
}

export const post = async (url: string, body: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let returnData: any;
    let returnStatus = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: any = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
        data: body,
        timeout: 6000
    };

    await axios(request).then((resp) => {
        returnStatus = resp.status;
        returnData = resp.data;
    }).catch((error) => {
        const err = error as AxiosError
        if (err.response) {
            returnStatus = err.response.status;
            returnData = JSON.stringify(err.response.data);
        }
    });

    return { status: returnStatus, data: returnData }
}